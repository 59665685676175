import React from "react";
import './Footer.css'

import creditCardsIcon from '../../../utils/assets/footer/credit-cards-icon.png';
import docChatIcon from '../../../utils/assets/logo/doc-chat-icon.png';
import emailIcon from '../../../utils/assets/footer/email-icon.png';
import customerServiceIcon  from '../../../utils/assets/footer/customer-service-icon.png'



const Footer = props => {
    const handleClick = ( sectionId ) => {
        sessionStorage.setItem('sectionName', sectionId);
        window.scroll(0, 0);
      };

     const handleGoTo = () =>{
        sessionStorage.setItem('sectionName', 'home');
     }
    return (
        <footer className="Footer__footer">

            <div className="Footer__main-footer-container">
                <div className="Footer__left-container" >
                    <div className="Footer__left-logo-container">
                        <img src={docChatIcon} alt="Doc Chat Icon" className="Footer__logo" />
                    </div>
                    <div className="Footer__left-contact-container" >
                        <img src={customerServiceIcon} alt="customerSeviceIcon" className="Footer__icons" />
                        <p>(888) 370-2066</p>
                    </div>
                    <div className="Footer__left-email-container">
                        <img src={emailIcon} alt="emailIcon" className="Footer__icons" />
                        <p>
                        help@docchatllc.com
                        </p>
                    </div>
                    <div>
                    <p className="Footer__business-address"> 
                    312 W 2nd St
Unit #A4817
Casper, WY 82601
                    </p>
                    </div>
                </div>

                <div className="Footer__menus-container">
                    <div className="Footer__center-container" >
                        <div className="Footer__center-menu-container">
                            <nav className="Footer__center-nav">
                                <ul>
                                    <li><a href="privacy-policy" onClick={() => handleClick('home')}>PRIVACY POLICY</a></li>
                                    <li><a href="refund-policy" onClick={() => handleClick('home')}>REFUND POLICY</a></li>
                                    <li><a href="terms-of-service" onClick={() => handleClick('home')}>TERMS OF SERVICE</a></li>
                                    <li><a href="cancel-service" onClick={() => handleClick('home')}>CANCEL SERVICE</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                   {/*  <div className="MainContainer__right-separator"></div> */}
                    <div className="Footer__right-container" >
                        <div className="Footer__right-menu-container">
                            <nav className="Footer__right-nav">
                                <ul >
                                    <li><a href="/" onClick={() => handleClick('home')}>HOME</a></li>
                                    <li><a href="about-us" onClick={() => handleClick('about-us')}>ABOUT US</a></li>
                                    <li><a href="price" onClick={() => handleClick('price')}>PRICE</a></li>
                                    <li><a href="contact-us" onClick={() => handleClick('contact-us')}>CONTACT</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            
            
            <div className="Footer__main-legal-description-container">
                <div className="Footer__legal-description-container">
                    <div className="Footer__legal-description">
                    <p>
                    Member, you will receive a text or email notification 48 hours prior to your 
                    monthly billing date. If you do not wish to proceed with your enrollment, you may call our 
                    Customer Service Department at (888) 370-2066 or click <a href="/cancel-service" onClick={handleGoTo}>here</a> to cancel and submit your request.
                    </p>
                    </div>
                </div>
                <div className="Footer__right-and-cards-icon-container">
                    <div className="Footer__copy-right-container">
                        <p>
                            <strong>Doc Chat © 2025 All Rights Reserved</strong>
                        </p>
                    </div>
                    <div className="Footer__card-icon-container">
                        <div className="Footer__credit-card-icons">
                            <img src={creditCardsIcon} alt="creditCardIcon" />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;